import { Injectable } from '@angular/core';
import { ApiService } from '@core/services/api.service';
import { StateService } from '@core/services/state.service';
import { environment } from 'environments/environment';
import {
  BehaviorSubject,
  of,
  Observable,
  throwError,
  forkJoin,
  combineLatest,
} from 'rxjs';
import { switchMap, take, tap, catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { Column, FizetesiMod, Partner, Penznem } from '../models/torzsek';
import { findByProp, stringifyIfJSON } from '../utils';
import { FizetesiModokService } from './fizetesi-modok.service';
import { PartnerekService } from './partnerek.service';
import { PenznemekService } from './penznemek.service';
import { ArkategoriakService } from './arkategoriak.service';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { AuthenticationService } from 'app/auth/service';
import { AfakulcsokService } from './afakulcsok.service';

@Injectable({
  providedIn: 'root',
})
export class ElolegszamlaService {
  private readonly apiEndpoint = 'elolegszamla';
  private readonly columnsEndpoint = 'elolegszamla/columns';
  private readonly listaEndpoint = 'service/helplib/oldal_lista/e';
  private readonly updateEndpoint = 'service/helplib/bizonylat_frissites/e';
  private readonly nyomtatasBeallitasEndpoint = 'service/helplib/nyomtatas_kapcsolo/e';
  private lastFetch: Date = new Date();
  private elolegszamla = JSON.parse(localStorage.getItem('elolegszamla'));


  private readonly _data = new BehaviorSubject<any[]>(null);
  private readonly _selectedData = new BehaviorSubject<any>(null);
  public data: any[] = [];
  private pageNum: number;
  public readonly streamEnd = new BehaviorSubject<boolean>(false);


  // public data$ = combineLatest([
  //   this._data,
  //   this.fizetesiModokService.data$,
  //   this.partnerekService.data$,
  //   this.penznemekService.data$,
  //   this.arkategoriakService.data$
  // ]).pipe(
  //   switchMap(([state, fizetesiModok, partnerek, penznemek, arkategoriak]) => {
  //     return state
  //       ? of(this.mapElolegszamlak(state, fizetesiModok, partnerek, penznemek, arkategoriak))
  //       : this.elolegszamla?.db > 0 ? this.getDBData() : this.fetchData()
  //     }),
  //     withLatestFrom(this.authService.currentUser$),
  //     map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
  // );

  public data$ = combineLatest([
    this._data,
    this.fizetesiModokService.data$,
    this.partnerekService.data$,
    this.penznemekService.data$,
    this.arkategoriakService.data$
  ]).pipe(
    switchMap(([state, fizetesiModok, partnerek, penznemek, arkategoriak]) =>
      state
        ? of(this.mapElolegszamlak(state, fizetesiModok, partnerek, penznemek, arkategoriak))
        : this.fetchData()
    )
  );

  constructor(
    private fizetesiModokService: FizetesiModokService,
    private partnerekService: PartnerekService,
    private penznemekService: PenznemekService,
    private api: ApiService,
    private stateService: StateService,
    private arkategoriakService: ArkategoriakService,
    private dbService: NgxIndexedDBService,
    private afakulcsokService: AfakulcsokService,
    private authService: AuthenticationService
  ) {}

  get columns$(): Observable<Column[]> {
    return this.getColumns();
  }

  // get initData$(): Observable<any[]> {
  //   return this._data.pipe(
  //     take(1),
  //     switchMap((state: any[]) =>
  //       state
  //         ? forkJoin([
  //             of(state),
  //             this.fizetesiModokService.initData$,
  //             this.partnerekService.initData$,
  //             this.penznemekService.initData$,
  //             this.arkategoriakService.initData$
  //           ]).pipe(
  //             map(([elolegszamlak, fizetesiModok, partnerek, penznemek, arkategoriak]) =>
  //               this.mapElolegszamlak(
  //                 elolegszamlak,
  //                 fizetesiModok,
  //                 partnerek,
  //                 penznemek,
  //                 arkategoriak
  //               )
  //             )
  //           )
  //         : JSON.parse(localStorage.getItem('elolegszamla'))?.db > 0 ? this.getDBData() : this.fetchData()
  //     ),
  //     withLatestFrom(this.authService.currentUser$),
  //     map(([data, user]) => data.filter((e: any) => e.oe_id === user.oe))
  //   );
  // }

  get initData$(): Observable<any[]> {
    return this._data.pipe(
      take(1),
      switchMap((state: any[]) =>
        state
          ? forkJoin([
              of(state),
              this.fizetesiModokService.initData$,
              this.partnerekService.initData$,
              this.penznemekService.initData$,
              this.arkategoriakService.initData$
            ]).pipe(
              map(([elolegszamlak, fizetesiModok, partnerek, penznemek, arkategoriak]) =>
                this.mapElolegszamlak(
                  elolegszamlak,
                  fizetesiModok,
                  partnerek,
                  penznemek,
                  arkategoriak
                )
              )
            )
          : this.fetchData()
      )
    );
  }

  add(data: any): Observable<any> {
    return this.api.post(this.apiEndpoint, this.toJSON(data)).pipe(
      tap((added: any) => {
        this.stateService.setStateOnAdd(added, this._data);
        //this.dbService.add('elolegszamla', added).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  delete(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id).pipe(
      tap((storno: any) => {
        this.stateService.setStateOnDelete(storno, this._data);
        //this.dbService.deleteByKey('elolegszamla', storno.id).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  sztorno(id: number): Observable<any> {
    return this.api.delete(this.apiEndpoint, id, 'sztornózás').pipe(
      tap((storno: any) => {
        this.stateService.setStateOnUpdate(storno, this._data);
        //this.dbService.update('elolegszamla', storno).subscribe();
      }),
      catchError((err: any) => throwError(err))
    );
  }

  getById(id: string): Observable<any> {
    return this.data$.pipe(
      take(1),
      map((state) => {
        // Find the data
        const data = state.find((item: any) => item.id === +id) || null;

        // Update the data
        this._selectedData.next(data);

        // Return the data
        return data;
      }),
      switchMap((data) => {
        if (id === 'new') {
          return of(null);
        }

        if (!data) {
          return throwError('Could not found data with id of ' + id + '!');
        }

        return of(data);
      })
    );
  }

  fetchAndMapDataById(id: number | string): Observable<any> {
    // Find the data in state
    const data = this._data.value.find((item: any) => item.id === +id) || null;

    // Update the data
    if(data.tetelek) {
      this._selectedData.next(data);
    }

    return data.tetelek ? of(data) : forkJoin([
      this.api.get(`${this.apiEndpoint}/${id}`).pipe(
        catchError((err: any) => {
          console.log(err);
          return of(null);
        })
      ),
      this.fizetesiModokService.initData$,
      this.partnerekService.initData$,
      this.penznemekService.initData$,
      this.arkategoriakService.initData$,
      this.afakulcsokService.initData$
    ]).pipe(
      map(([elolegszamla, fizetesiModok, partnerek, penznemek, arkategoriak, afakulcsok]) => {
        const retVal = this.mapElolegszamla(
          elolegszamla,
          fizetesiModok,
          partnerek,
          penznemek,
          arkategoriak,
          afakulcsok
        );
        this.stateService.setStateOnUpdate(retVal, this._data);
        this._selectedData.next(retVal);
        return retVal;
      }
      )
    );
  }

  update(data: any): Observable<any> {
    return this.api
      .put(`${this.apiEndpoint}/${data.id}`, this.toJSON(data))
      .pipe(
        tap((updated: any) => {
          this.stateService.setStateOnUpdate(updated, this._data);
          //this.dbService.update('elolegszamla', updated).subscribe();
        }),
        catchError((err: any) => throwError(err))
      );
  }

  generatePdf(payload: { id: number; sztorno?: boolean }): Observable<any> {
    return this.api
      .postBizonylat(`${environment.pdfGeneralas}/${this.apiEndpoint}`, payload)
      .pipe(
        map(({ url }) => `${environment.uploads}${url}`),
        catchError(() => of(null))
      );
  }

  retryNAV(szamla_id: number): Observable<any> {
    return this.api
      .post(environment.retryNAVApi, { szamla_id, vegszamla: false })
      .pipe(
        tap((navUzenetJSON: any) => {
          const parsed = this.parseNAVUzenet(navUzenetJSON);
          // Ha volt változás akkor state update
          if (navUzenetJSON) {
            const elById = findByProp(this._data.value, 'id', szamla_id);

            this.stateService.setStateOnUpdate(
              { ...elById, nav_uzenet: parsed },
              this._data
            );
          }
        }),
        catchError((err) => {
          console.log('NAV újraküldés hiba', err);

          return throwError(err);
        })
      );
  }

  getNAVStatus(szamla_id: number): Observable<any> {
    return this.api
      .post(environment.szamlaStatusApi, { szamla_id, vegszamla: false })
      .pipe(
        catchError((err) => {
          console.log('NAV újraküldés hiba', err);

          return throwError(err);
        })
      );
  }

  getBizonylatValtozas(): any {
    this.lastFetch = new Date();
    const oe = JSON.parse(localStorage.getItem('currentUser')).oe;
    const last = JSON.parse(localStorage.getItem('elolegszamla'))?.lastFetch;
    return this.api.postBizonylat(`${this.updateEndpoint}/${oe}/${last}`, "")
           .pipe(
            take(1),
            tap(valtozas => {
              if (this._data.value.length == 0) {
                //this.fetchData().subscribe();
              } else {
                if (valtozas.valtozasok.length > 0) {
                  valtozas.valtozasok.forEach((bizonylat) => {
                    const biz = findByProp(this._data.value, "id", bizonylat.id);
                    if (biz) {
                      this.stateService.setStateOnUpdate(bizonylat, this._data);
                    } else {
                      this.stateService.setStateOnAdd(bizonylat, this._data);
                    }
                  });
                }
                if (valtozas.torolt_lista.length > 0) {
                  valtozas.torolt_lista.forEach((bizonylat) => {
                    const biz = findByProp(
                      this._data.value,
                      "id",
                      bizonylat.modul_id
                    );
                    if (biz) {
                      this.stateService.setStateOnDelete(biz, this._data);
                    }
                  });
                }
              }
    
              localStorage.setItem(
                "elolegszamla",
                JSON.stringify({
                  db: this._data.value.length,
                  lastFetch: this.lastFetch,
                })
              );
            }))
  }

  fetchData(): Observable<any[]> {
    this.lastFetch = new Date();
    return forkJoin([
      this.api.get(`${this.apiEndpoint}`).pipe(
        // bufferCount(25),
        // concatMap(objs => of(objs)),
        tap((data: any[]) => {
          this._data.next(data);
        }),
        catchError((err: any) => {
          console.log(err);
          return of([]);
        })
      ),
      this.fizetesiModokService.initData$,
      this.partnerekService.initData$,
      this.penznemekService.initData$,
      this.arkategoriakService.initData$
    ]).pipe(
      map(([elolegszamlak, fizetesiModok, partnerek, penznemek, arkategoriak]) => {
        localStorage.setItem('elolegszamla', JSON.stringify({db: elolegszamlak.length, lastFetch: this.lastFetch}));

        return this.mapElolegszamlak(
          elolegszamlak,
          fizetesiModok,
          partnerek,
          penznemek,
          arkategoriak
        )
      })
    );
  }

  getDBData(): Observable<any> {
    console.log('get elolegszamla from indexedDB')
    // return this.dbService.getAll('elolegszamla').pipe(
    //   tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
    // );
    return forkJoin([
      this.dbService.getAll('elolegszamla').pipe(
        tap((data: any[]) => this._data.next(data.sort((a,b) => b.id - a.id))),
      ),
      this.fizetesiModokService.initData$,
      this.partnerekService.initData$,
      this.penznemekService.initData$,
      this.arkategoriakService.initData$
    ]).pipe(
      map(([elolegszamlak, fizetesiModok, partnerek, penznemek, arkategoriak]) => {
        return this.mapElolegszamlak(
          elolegszamlak,
          fizetesiModok,
          partnerek,
          penznemek,
          arkategoriak
        )
      })
    );
  }

  private getColumns(): Observable<Column[]> {
    const columns = JSON.parse(localStorage.getItem('elolegszamla_columns'));

    if(columns) {
      return of(columns);
    } else {
      return this.api.get(this.columnsEndpoint).pipe(
        map((data: Column[]) => {
          const columns = [
            ...data,
            { name: 'arkategoria_id', nullable: false },
            { name: 'tetelek', nullable: true },
            { name: 'megjegyzes_nyomtat', nullable: false, hidden: true, default: 1 },
          ];
  
          const mappedColumns = columns.map((c: Column) =>
            c.name === 'szamla_kelte' ? { ...c, disabled: true } : c
          );
  
          localStorage.setItem('elolegszamla_columns', JSON.stringify(mappedColumns));
  
          return mappedColumns;
        }),
        catchError((err: any) => {
          console.log(err);
  
          return of([]);
        })
      );
    }
  }

  private mapElolegszamlak(
    elolegszamlak: any[],
    fizetesiModok: FizetesiMod[],
    partnerek: Partner[],
    penznemek: Penznem[],
    arkategoriak: any[]
  ): any[] {
    return elolegszamlak.map((e: any) => {
      const penznemObj: Penznem = findByProp(penznemek, 'id', e.penznem_id);
      const penznem = penznemObj?.outfix || penznemObj?.prefix;

      return {
        ...e,
        nav_uzenet: this.parseNAVUzenet(e.nav_uzenet),
        partner: findByProp(partnerek, 'id', e.partner_id)?.nev,
        teljesites_datum: new Date(e.teljesites_datum),
        szamla_kelte: new Date(e.szamla_kelte),
        fizetesi_hatarido: new Date(e.fizetesi_hatarido),
        penznem,
        //afakulcs: e.tetelek[0]?.afakulcs,
        fizetesiMod: findByProp(fizetesiModok, 'id', e.fizetesi_mod_id)?.nev,
        //tetelek: e.tetelek.map((t: any) => ({ ...t, penznem, arkategoria: findByProp(arkategoriak, 'id', t.arkategoria_id)?.nev, })),
      };
    });
  }

  private mapElolegszamla(
    elolegszamla: any,
    fizetesiModok: FizetesiMod[],
    partnerek: Partner[],
    penznemek: Penznem[],
    arkategoriak: any[],
    afakulcsok: any[]
  ): any[] {
      const penznemObj: Penznem = findByProp(penznemek, 'id', elolegszamla.penznem_id);
      const penznem = penznemObj?.outfix || penznemObj?.prefix;

      return {
        ...elolegszamla,
        nav_uzenet: this.parseNAVUzenet(elolegszamla.nav_uzenet),
        partner: findByProp(partnerek, 'id', elolegszamla.partner_id)?.nev,
        teljesites_datum: new Date(elolegszamla.teljesites_datum),
        szamla_kelte: new Date(elolegszamla.szamla_kelte),
        fizetesi_hatarido: new Date(elolegszamla.fizetesi_hatarido),
        penznem,
        //afakulcs: e.tetelek[0]?.afakulcs,
        fizetesiMod: findByProp(fizetesiModok, 'id', elolegszamla.fizetesi_mod_id)?.nev,
        tetelek: elolegszamla.tetelek.map((t: any) => ({ ...t, penznem, arkategoria: findByProp(arkategoriak, 'id', t.arkategoria_id)?.nev, afakulcs_nev: findByProp(afakulcsok, 'id', t.afakulcs_id)?.nev  })),
      };
  }

  private toJSON(data: any): any {
    return {
      ...data,
      cim: stringifyIfJSON(data.cim),
      teljesites_datum: new Date(
        new Date(data.teljesites_datum).setHours(12, 0)
      ),
      szamla_kelte: new Date(new Date(data.szamla_kelte).setHours(12, 0)),
      fizetesi_hatarido: new Date(
        new Date(data.fizetesi_hatarido).setHours(12, 0)
      ),
    };
  }

  private parseNAVUzenet(
    navUzenetJSON: any
  ): { tranzakciosId: string; statusz: 'DONE' | 'ABORTED' } | null {
    // Nav üzenet
    if(typeof navUzenetJSON === 'object' && navUzenetJSON !== null) {
      return navUzenetJSON ? { ...navUzenetJSON, statusz: navUzenetJSON?.statusz } : null;
    } else {
      const parsed = navUzenetJSON ? JSON.parse(navUzenetJSON) : null;

      return parsed ? { ...parsed, statusz: parsed?.statusz?.['0'] } : null;
    }

  }

  getMennyiseg(): any {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));

    return this.api.postBizonylat(`service/helplib/ossz_bizonylat/e/${currentUser.id}`, "");
  }

  clearData(): any {
    this.data = [];
  }

  nyomtatasBeallitas(row: any, megjegyzes_nyomtat: number, hivatkozas_nyomtat: number = 0): Observable<any> {
    return this.api
    .post(`${this.nyomtatasBeallitasEndpoint}/${row.id}/${megjegyzes_nyomtat}/${hivatkozas_nyomtat}`, '')
    .pipe(
      tap((updated: any) => {
        this.getBizonylatValtozas();
      }),
      catchError(() => of(null))
    );
  }
}
